
<template>
<form autocomplete="off" class="">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row g-sm-4 g-3">
    <div class="col-12">
      <h5 class="mb-2">BASIC DETAILS</h5>
      <p class="mb-0">Complete the basic information below:</p>
    </div>
    <div class="col-12">
      <label class="form-label" for="inputParties">Case Parties</label>
      <input id="inputParties" type="text" class="form-control" v-model="data.parties"
      :class="{ 'is-invalid': v$.data.parties.$error }" placeholder="Parties Involved" name="parties" />
      <div v-for="(item, index) in v$.data.parties.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-between align-items-center">
        <label>Case Number Type</label>
      </div>
      <select class="form-select" v-model="data.number_type" 
          :class="{ 'is-invalid': v$.data.number_type.$error  }"
          name="number_type" placeholder="--select number type--">
          <option disabled value="" key="-1">-- select number type--</option>
          <option :value="number_type.value" v-for="(number_type, catIndex) in numberTypes" 
          :key="catIndex">{{ number_type.label }}</option>
      </select>
      <div v-for="(item, index) in v$.data.number_type.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <label class="form-label" for="caseNumber">{{data.number_type || 'Case'}} Number</label>
      <input id="caseNumber" autocomplete="false" type="text" class="form-control" v-model="data.case_number"
      :class="{ 'is-invalid': v$.data.case_number.$error  }" :placeholder="`${data.number_type || 'Case'} number`" name="case_number" />
      <div v-for="(item, index) in v$.data.case_number.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-between align-items-center">
        <label>Category</label>
      </div>
      <select class="form-select" v-model="data.case_category_id" 
          :class="{ 'is-invalid': v$.data.case_category_id.$error  }"
          name="input-category" placeholder="--select category--">
          <option disabled value="" key="-1">-- select category--</option>
          <option :value="category.id" v-for="(category, catIndex) in categoryList" 
          :key="catIndex">{{ category.title }}</option>
      </select>
      <div v-for="(item, index) in v$.data.case_category_id.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-between align-items-center">
        <label>Status</label>
      </div>
      <select class="form-select" v-model="data.status_id" 
          :class="{ 'is-invalid': v$.data.status_id.$error  }"
          name="input-status" placeholder="--select status--">
          <option disabled value="" key="-1">-- select status--</option>
          <option :value="status.id" v-for="(status, stIndex) in statusList" 
          :key="stIndex">{{ status.title }}</option>
      </select>
      <div v-for="(item, index) in v$.data.status_id.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="mt-3">
        <b-button  size="lg" variant="primary" 
          @click.prevent="editMode? confirmUpdate() : confirmCreate()"
            type="button">{{editMode? 'Update':'Submit'}}</b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";
import IsLoading from '@/components/IsLoading.vue'

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Multiselect,
    IsLoading,
    flatPickr
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      data: {
        parties: "",
        number_type: "",
        case_number: "",
        case_category_id: "",
        status_id: ""
      },
      dateConfig:{
        wrap: true, 
        altFormat: 'M j, Y H:i',
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        enableTime: true,
      },
      mirrorData: null,
      numberTypes: [
        { label: "Charge number", value: 'Charge'},
        { label: "Suit number", value: 'Suit'},
        { label: "Appeal number", value: 'Appeal'}
      ]
    }
  },
  validations() {
    return {
      data:{
        parties: {
          required: helpers.withMessage("Parties is required", required),
        },
        number_type: {
          required: helpers.withMessage("Number Type is required", required),
        },
        case_number: {
          required: helpers.withMessage("Charge or Suit number is required", required),
        },
        case_category_id: {
          required: helpers.withMessage("Choose category", required),
        },
        status_id: {
          required: helpers.withMessage("The status is required", required),
        },
      }
    }
  },
  computed:{
    department(){
      return this.$store.state.dashboardData.department
    },
    categoryList(){
      return this.$store.state.caseCategory.list
    },
    statusList(){
      return this.$store.state.caseStatus.list
    },
  },
  methods: {
    confirmCreate(){
      this.v$.$validate().then(result =>{
        if (!result) {this.alertError(this.v$.$errors[0].$message); return };
        this.confirmAction({
          text: "Please authorize this action, confirming that all entered case details are accurate.",
        }).then((result) => {
          if (result.value) { this.create(); }
        });
      })
    },
    create() {
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post('/cases/create', formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('addedItem', response.data.data)
              // this.$store.commit("case/ADD_ITEM", response.data.data)
            this.resetForm();
          }
      })
    },
    confirmUpdate(){
      this.v$.$validate().then(result =>{
        if (!result) {this.alertError(this.v$.$errors[0].$message); return };
        this.confirmAction({
          text: "Please authorize this action, confirming that all entered case details are accurate.",
        }).then((result) => {
          if (result.value) { this.update(); }
        });
      })
    },
    update(){
      const formData = new FormData()
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/cases/${this.editItem.id}/update`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('updatedItem', response.data.data)
            this.resetForm()
          }
        })
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit("closeMe")
    },
    async getOptionData(){
      this.isLoading = true;
      this.$store.dispatch('caseCategory/fetchAllCategories')
      this.$store.dispatch('caseStatus/fetchAllItems')
        .then(response => this.isLoading = false );
    },
  },
  mounted(){
    this.getOptionData()
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
