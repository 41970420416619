
<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Add Case</h5>
      <div class="loading-viewport">
        <div class="card">
          <div class="card-body">
            <case-form @addedItem="addedItem($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseForm from '@/components/forms/CaseForm.vue';

export default {
  components: { CaseForm },
  methods:{
    addedItem(item){
      this.$router.push({path: `/cases/${item.id}` });
    }
  }
}
</script>
